<template>
  <div>
    <div class="top">
      <div class="notice" @click="getNotices">
        <div></div>
        <div class="notice_text">
          <span class="iconfont icon-tongzhi icon" @click="getNotices"></span>
          <div class="titles">通知</div>
        </div>
      </div>
      <div class="avatar">
        <div class="imgbox">
          <img style="width: 100%" v-if="photo" :src="this.oss_url + photo" @click="ImagePreview" />
          <img style="width: 100%" v-else src="../../assets/img/nohead.png" alt="" />
        </div>
        <div class="avatar-font">
          <p class="p1">
             <span>{{ this.user_name }}</span>
            <img src="../../assets/member/icon_qihuan@2x.png" @click="cut" >
          </p>
          <p class="p2" v-if="this.mobile">{{ this.mobile }}</p>
          <p class="p2" v-else>暂无获取到手机号</p>
        </div>
        <img class="top-img" src="../../assets/member/btn_huiyuan@2x.png" @click="pay()" />
      </div>
      <Datepicker class="time" @func="getMsgFormSon"></Datepicker>
    </div>
    <div class="people">
      <div class="warp">
        <div class="ptop">
          <img src="@/assets/img/Slice56@2x.png">
          <span>接送管理 </span>
        </div>
        <router-link :to="is_pay === true ? '/pick_list' : '/pay'">
          <div class="pcont">
            <div>
              <div class="onebox" v-for="(item, index) in pickup" :key="index">
                <div class="one">
                  <div>
                      <img v-if="item.face" :src="item.face" @error="error(index)">
                      <img v-else src="../../assets/img/nohead.png">
                  </div>
                  <div><span style="font-size: 3vw;">{{ item.user_name }}</span></div>
                </div>
              </div>
            </div>
            <div>
              <img src="../../assets/img/jiantou.png">
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="card">
      <div class="cont_tit">
        <p class="title">到校情况</p>
        <div>
          <!--  <van-button type="info" size="mini" @click="pay">测试支付</van-button> -->
          <van-button type="info" size="mini" @click="to">发起请假</van-button>
        </div>
      </div>
      <div>
        <van-steps :active="today" class="steps" active-icon="checked" active-color="#5380FF" direction="vertical">
          <van-step v-for="(item, index) in list" :key="index">
            <div class="van">
              <div class="week">
                <p>{{ item.week }}</p>
                <p class="p2">{{ item.date }}</p>
              </div>
              <div class="steps-card">
                <div class="col" v-if="item.status == '未打卡'">
                  {{ item.status }}
                </div>
                <div class="col1" v-if="item.status == '迟到'">
                  {{ item.status }}
                </div>
                <div class="col2" v-if="item.status == '正常'">
                  {{ item.status }}
                </div>
                <van-row class="row1" type="flex">
                  <van-col span="13">打卡时间</van-col>
                  <van-col span="6">体温</van-col>
                </van-row>
                <van-row class="row2" type="flex">
                  <van-col span="13">{{ item.attendance_time }}</van-col>
                  <van-col span="6">{{ item.temperature }}</van-col>
                </van-row>
              </div>
            </div>
          </van-step>
        </van-steps>
      </div>
    </div>
    <div style="height: 10vw"></div>
    <Foot></Foot>
  </div>
</template>
<script>
import Foot from "@/components/Foot.vue";
import Datepicker from "@/components/Datepicker.vue";
import { Getparents } from "@/api/clock_in/parents";
import { getlist, edit_info } from '@/api/pickup/list'
import { isPayed, feeItem } from "@/api/pay";
import { getMobile, getUserId, getUserName } from "../../utils/user.js";
import {ImagePreview, Notify} from 'vant';

export default {
  components: { Foot, Datepicker },
  data() {
    return {
      date: "",
      show: false,
      t_ime: {
        datetime: "",
      }, // 插件传来时间
      list: "",
      oss_url: process.env.VUE_APP_OSS_URL,
      pickup: [],
      id: '',
      photo: '',
      mobile: '',
      user_name: '',
      today: '3',
      fee_item_id: '',
      is_pay: false
    };
  },
  created() {
    this.id = getUserId();
    this.user_name = getUserName();
    this.getpick()
    this.getinfo()
    this.mobile = getMobile();
    this.getIsPayed();
  },
  methods: {
    getinfo() {
      edit_info(this.id).then(res => {
        this.photo = res.data.face
      })
    },
    getIsPayed() {
      isPayed().then((res) => {
        this.is_pay = res.data.is_pay
      })
    },
    // 获取考勤数据
    getlist() {
      Getparents(this.t_ime).then((res) => {
        this.list = res.data;
        let today = new Date();
        let weekday = today.getDay();
        this.today = weekday-1
      });
    },
    // 接受子组件传值
    getMsgFormSon(star, end) {
      this.t_ime = {
        start_time: star,
        end_time: end,
      };
      this.getlist();
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
    },
    to() {
      this.$router.push("/classleave");
    },
    pay() {
      feeItem().then((res) => {
        this.fee_item_id = res.data.id;
        if (this.fee_item_id) {
          this.$router.push("/pay");
        } else {
          Notify({ type: "warning", message: '收费项不存在', duration: 2000 });
        }
      })
    },
    // 切换身份
    cut() {
      this.$store.commit("RESET_STATE");
      this.$router.push("/choose");
    },
    getNotices() {
      this.$router.push("/parents");
    },
    // 获取接送人
    getpick() {
      getlist().then(res => {
        this.pickup = res.data
      })
    },
    ImagePreview() {
      ImagePreview([
        this.oss_url + this.photo,
      ]);
    },
    error(i){
      this.pickup[i].face = ''
    }
  },
};
</script>

<style lang="less" scoped src="./index.less"></style>
